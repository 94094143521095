/* eslint-disable */
var icon = require('vue-svgicon');
icon.register({
    arrows: {
        width: 16,
        height: 16,
        viewBox: '0 0 16 16',
        data: '<path pid="0" d="M1.146 8.354a.5.5 0 010-.708l2-2a.5.5 0 11.708.708L2.707 7.5h10.586l-1.147-1.146a.5.5 0 01.708-.708l2 2a.5.5 0 010 .708l-2 2a.5.5 0 01-.708-.708L13.293 8.5H2.707l1.147 1.146a.5.5 0 01-.708.708z"/>',
    },
});
